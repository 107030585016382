import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import { initConfig } from './config';
import * as serviceWorker from './serviceWorker';

let timer: NodeJS.Timeout;

type TokensType = {
    token: string;
    refreshToken: string;
};

declare global {
    interface Window {
        adminTokens: TokensType;
    }
}

//@ts-ignore
if (window.env) {
    initConfig();
    ReactDOM.render(<App />, document.getElementById('root'));
} else {
    timer = setInterval(() => {
        //@ts-ignore
        if (window.env) {
            clearInterval(timer);
            initConfig();
            ReactDOM.render(<App />, document.getElementById('root'));
        }
    }, 200);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
