import React, { useState } from 'react';

import { Router, Switch, Route, Redirect } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { ConfigProvider } from 'antd';
import {
    QueryParamProvider,
    transformSearchStringJsonSafe,
    ExtendedStringifyOptions,
} from 'use-query-params';
import 'url-search-params-polyfill';
import { lazy } from '@loadable/component';

import AuthService from './aqua-delivery-web-client-ui/AuthService';
import I18n, { I18nSetConfig, I18nContext } from './aqua-delivery-web-client-ui/i18n';
import SuspenseWithLoader from './aqua-delivery-web-client-ui/components/loader/SuspenseWithLoader';
import LoaderContainer from './aqua-delivery-web-client-ui/components/loader/LoaderContainer';
import ErrorBoundary from './aqua-delivery-web-client-ui/components/ErrorBoundary';
import EmptyPanel from './aqua-delivery-web-client-ui/components/EmptyPanel';
import Loader from './aqua-delivery-web-client-ui/components/loader/Loader';
import translations from './translations';

import './aqua-delivery-web-client-ui/assets/styles/common.less';

const history = createBrowserHistory();

const LoginAsync = lazy(() => import('./routes/login'));
const PartnerAsync = lazy(() => import('./routes/cabinet'));
const Page404Async = lazy(
    () => import('./aqua-delivery-web-client-ui/components/static-pages/Page404'),
);

I18nSetConfig({ translations });

const queryStringifyOptions: ExtendedStringifyOptions = {
    transformSearchString: transformSearchStringJsonSafe,
};

const App: React.FC = () => {
    React.useEffect(() => {
        window.document.title = 'Aqua Delivery';
    }, []);

    const [locale, setLocale] = useState(I18n.locale);

    return (
        <I18nContext.Provider
            value={{
                locale,
                setLocale: locale => {
                    I18nSetConfig({ locale });
                    setLocale(locale);
                },
            }}
        >
            <ConfigProvider renderEmpty={() => <EmptyPanel />}>
                <LoaderContainer height={window.innerHeight}>
                    <Router history={history}>
                        <QueryParamProvider
                            ReactRouterRoute={Route}
                            stringifyOptions={queryStringifyOptions}
                        >
                            <ErrorBoundary>
                                <SuspenseWithLoader fallback={<Loader loading={true} />}>
                                    <Switch>
                                        {AuthService.isLoggedIn() && (
                                            <Redirect from="/login" to="/" />
                                        )}
                                        <Route
                                            history={history}
                                            path="/login"
                                            component={LoginAsync}
                                            exact
                                        />
                                        <Route
                                            history={history}
                                            path="/"
                                            component={PartnerAsync}
                                        />
                                        <Route component={Page404Async} />
                                    </Switch>
                                </SuspenseWithLoader>
                            </ErrorBoundary>
                        </QueryParamProvider>
                    </Router>
                </LoaderContainer>
            </ConfigProvider>
        </I18nContext.Provider>
    );
};

export default App;
