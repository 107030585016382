import I18n from './aqua-delivery-web-client-ui/i18n';
import {
    Source,
    setConfig as setCustomFetchConfig,
} from './aqua-delivery-web-client-ui/customFetch';

export type ServiceName = keyof ConfigApp['api'];

type Api<T> = T & {
    domain: string;
    base: string;
    enrichToken?: string;
};

type ConfigApp = {
    source: Source;
    version: string;
    tokenStoreKey: string;
    refreshTokenStoreKey: string;
    invoiceClientUrl: string;
    partnerClientUrl: string;
    addressAutocomplete: string;
    api: {
        auth: Api<{
            getToken: string;
            refreshTokenUrl: string;
            tokenRequest: string;
            immediateChangePassword: string;
        }>;
        partner: Api<{}>;
        courier: Api<{}>;
        temporal: Api<{}>;
        wiki: Api<{}>;
        sms: Api<{}>;
        invoice: Api<{}>;
        payment: Api<{}>;
        pricingPlans: Api<{}>;
        deliveryPeriod: Api<{}>;
        receivable: Api<{}>;
        landing: Api<{}>;
        report: Api<{}>;
        notificationBot: Api<{}>;
        marketing: Api<{}>;
        chat: Api<{}>;
        onlineKassa: Api<{}>;
    };
};

const devAuthBaseUrlStoreKey = 'auth_service_url';
const devPartnerBaseUrlStoreKey = 'partner_service_url';
const devCourierBaseUrlStoreKey = 'courier_service_url';
const devTemporalBaseUrlStoreKey = 'temporal_service_url';
const devWikiBaseUrlStoreKey = 'wiki_service_url';
const devSMSBaseUrlStoreKey = 'sms_service_url';
const devInvoiceBaseUrlStoreKey = 'invoice_service_url';
const devPaymentBaseUrlStoreKey = 'payment_service_url';
const devPricingPlansBaseUrlStoreKey = 'pricing_plans_service_url';
const devDeliveryPeriodBaseUrlStoreKey = 'delivery_period_service_url';
const devReceivableBaseUrlStoreKey = 'receivable_service_url';
const devLandingBaseUrlStoreKey = 'landing_service_url';
const devReportBaseUrlStoreKey = 'report_service_url';
const devNotificationBotBaseUrlStoreKey = 'notification_bot_service_url';
const devMarketingBaseUrlStoreKey = 'marketing_service_url';
const devChatBaseUrlStoreKey = 'chat_service_url';
const devOnlineKassaBaseUrlStoreKey = 'online_kassa_service_url';

export const getEnvVariable = (key: string) => {
    //@ts-ignore
    const env = window.env;
    let values;
    if (env[I18n.locale]) {
        values = env[I18n.locale][key];
    } else if (env['ru'][key]) {
        values = env['ru'][key];
    }

    if (values) return values;
    throw Error(`Need add key ${key} into .env.local`);
};

const getBaseUrl = (localStorageKey: string, envKey: string) => {
    const url = localStorage.getItem(localStorageKey) || getEnvVariable(envKey);
    return url;
};

let conf: ConfigApp | undefined = undefined;

export const initConfig = () => {
    const baseAuthApi = getBaseUrl(devAuthBaseUrlStoreKey, 'authServiceUrl');
    const basePartnerApi = getBaseUrl(devPartnerBaseUrlStoreKey, 'partnerServiceUrl');
    const baseCourierApi = getBaseUrl(devCourierBaseUrlStoreKey, 'courierServiceUrl');
    const baseTemporalApi = getBaseUrl(devTemporalBaseUrlStoreKey, 'temporalServiceUrl');
    const baseInvoiceApi = getBaseUrl(devInvoiceBaseUrlStoreKey, 'invoiceServiceUrl');
    const basePaymentApi = getBaseUrl(devPaymentBaseUrlStoreKey, 'paymentServiceUrl');
    const basePricingPlansApi = getBaseUrl(devPricingPlansBaseUrlStoreKey, 'pricingPlanServiceUrl');
    const baseDeliveryPeriodApi = getBaseUrl(
        devDeliveryPeriodBaseUrlStoreKey,
        'deliveryPeriodServiceUrl',
    );
    const baseReceivableApi = getBaseUrl(devReceivableBaseUrlStoreKey, 'receivablesServiceUrl');
    const baseLandingApi = getBaseUrl(devLandingBaseUrlStoreKey, 'landingServiceUrl');
    const baseReportApi = getBaseUrl(devReportBaseUrlStoreKey, 'reportServiceUrl');
    const baseWikiApi = getBaseUrl(devWikiBaseUrlStoreKey, 'wikiServiceUrl');
    const baseSMSApi = getBaseUrl(devSMSBaseUrlStoreKey, 'smsServiceUrl');
    const baseNotificationBotApi = getBaseUrl(
        devNotificationBotBaseUrlStoreKey,
        'notificationBotServiceUrl',
    );
    const baseMarketingApi = getBaseUrl(devMarketingBaseUrlStoreKey, 'marketingServiceUrl');
    const baseChatApi = getBaseUrl(devChatBaseUrlStoreKey, 'chatServiceUrl');
    const baseOnlineKassaApi = getBaseUrl(devOnlineKassaBaseUrlStoreKey, 'onlineKassaServiceUrl');

    const invoiceClientUrl = getEnvVariable('invoiceWebclientUrl');
    const partnerClientUrl = getEnvVariable('partnerWebclientUrl');
    const addressAutocomplete = getEnvVariable('addressAutocomplete');

    conf = {
        source: 'admin',
        version: '0.0.2',
        tokenStoreKey: 'token',
        refreshTokenStoreKey: 'refresh_token',
        invoiceClientUrl,
        partnerClientUrl,
        addressAutocomplete,
        api: {
            auth: {
                domain: baseAuthApi,
                base: `${baseAuthApi}/auth/graphql`,
                getToken: `${baseAuthApi}/auth/login/token/get`,
                refreshTokenUrl: `${baseAuthApi}/auth/login/token/refresh`,
                tokenRequest: `${baseAuthApi}/auth/token_requests`,
                immediateChangePassword: `${baseAuthApi}/auth/immediate_reset_password_requests`,
            },
            partner: {
                domain: basePartnerApi,
                base: `${basePartnerApi}/partner/graphql`,
            },
            courier: {
                domain: baseCourierApi,
                base: `${baseCourierApi}/courier/graphql`,
            },
            temporal: {
                domain: baseTemporalApi,
                base: `${baseTemporalApi}/graphql`,
            },
            wiki: {
                domain: baseWikiApi,
                base: `${baseWikiApi}/wiki/graphql`,
            },
            sms: {
                domain: baseSMSApi,
                base: baseSMSApi,
            },
            invoice: {
                domain: baseInvoiceApi,
                base: `${baseInvoiceApi}/invoice/graphql`,
            },
            payment: {
                domain: basePaymentApi,
                base: `${basePaymentApi}/payment/graphql`,
                enrichToken: `${basePartnerApi}/partner/enrich_token_requests`,
            },
            pricingPlans: {
                domain: basePricingPlansApi,
                base: `${basePricingPlansApi}/pricing-plan/graphql`,
                enrichToken: `${basePricingPlansApi}/partner/enrich_token_requests`,
            },
            deliveryPeriod: {
                domain: baseDeliveryPeriodApi,
                base: `${baseDeliveryPeriodApi}/delivery-period/graphql`,
            },
            receivable: {
                domain: baseReceivableApi,
                base: `${baseReceivableApi}/receivable/graphql`,
            },
            landing: {
                domain: baseLandingApi,
                base: `${baseLandingApi}/landing/graphql`,
            },
            report: {
                domain: baseReportApi,
                base: `${baseReportApi}/report/graphql`,
            },
            notificationBot: {
                domain: baseNotificationBotApi,
                base: `${baseNotificationBotApi}/notification-chat-bot/graphql`,
            },
            marketing: {
                domain: baseMarketingApi,
                base: `${baseMarketingApi}/marketing/graphql`,
            },
            chat: {
                domain: baseChatApi,
                base: `${baseChatApi}/chat/graphql`,
            },
            onlineKassa: {
                domain: baseOnlineKassaApi,
                base: `${baseOnlineKassaApi}/online-kassa/graphql`,
            },
        },
    };

    setCustomFetchConfig(config.source, config.version);
};

export const config = {
    get source() {
        return conf!.source;
    },
    get version() {
        return conf!.version;
    },
    get tokenStoreKey() {
        return conf!.tokenStoreKey;
    },
    get refreshTokenStoreKey() {
        return conf!.refreshTokenStoreKey;
    },
    get invoiceClientUrl() {
        return conf!.invoiceClientUrl;
    },
    get partnerClientUrl() {
        return conf!.partnerClientUrl;
    },
    get api() {
        return conf!.api;
    },
    get addressAutocomplete() {
        return conf!.addressAutocomplete;
    },
};
